export const useMoneyHelper = () => {
  function convertToEuroNumber(centValue: number) {
    const value = centValue / 100
    return Number(value.toFixed(2))
  }

  function convertToCentNumber(euroValue: number) {
    return Math.round(euroValue * 100)
  }

  function formatCentToEuro(centValue: number) {
    const euroNumber = convertToEuroNumber(centValue)
    new Intl.NumberFormat('de-DE').format(euroNumber)
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'code',
    })
      .format(euroNumber)
      .replace('EUR', '')
  }

  function calculatePriceWithouVatInCent(grossPrice: number, vatPercentage: number) {
    if (!grossPrice || !vatPercentage) return 0
    const netPrice = grossPrice / (1 + vatPercentage / 100)
    return Math.round(netPrice)
  }

  function calculateDiscoutAmountInCent(nettPrice: number, discountPercentage: number) {
    if (!nettPrice || !discountPercentage) return 0
    const discountAmount = (nettPrice * discountPercentage) / 100
    return Math.round(discountAmount)
  }

  function calculateAndFormatPriceWithouVat(grossPrice: number, vatPercentage: number) {
    if (!grossPrice || !vatPercentage) return '0,00'
    const euroNetPrice = calculatePriceWithouVatInCent(grossPrice, vatPercentage) / 100
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'code',
    })
      .format(euroNetPrice)
      .replace('EUR', '')
  }

  return {
    convertToEuroNumber,
    convertToCentNumber,
    formatCentToEuro,
    calculateAndFormatPriceWithouVat,
    calculatePriceWithouVatInCent,
    calculateDiscoutAmountInCent,
  }
}
